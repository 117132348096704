export default {
  __name: 'footer',
  setup(__props) {

const logo = require('../../../assets/logo.svg');
const socialList = [
  {
    icon: 'fa-facebook-f',
    url: 'https://www.facebook.com/runprofllc/',
  },
  {
    icon: 'fa-linkedin-in',
    url: 'https://eg.linkedin.com/company/runprofllc',
  },
  {
    icon: 'fa-twitter',
    url: 'https://twitter.com/runprof_M',
  },
  {
    icon: 'fab fa-instagram',
    url: 'http://bit.ly/RP-instagram',
  },
  {
    icon: 'fab fa-behance',
    url: 'https://www.behance.net/runprofllc',
  },
];
console.log(logo);
console.log(socialList);

return { __sfc: true,logo, socialList }
}

}