
import Footer from '../../footer/components/footer';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    Footer,
  },
  data() {
    return {};
  },
  async created() {
    await this.doFetchProject(this.$route.params.id);
    // document.getElementById('background-triangle').style.background="linear-gradient(to bottom right, transparent 0%, transparent 50%," + this.project.hexa + " 50%",+ this.project.hexa + " 100% )"
  },
  computed: {
    ...mapGetters({
      project: 'portfolio/project',
      nextProject: 'portfolio/nextProject',
      previousProject: 'portfolio/previousProject',
      loading: 'portfolio/loading',
    }),
    projectScreens() {
      if (this.project.details.screens) {
        var screens = this.project.details.screens;
        return screens.sort((a, b) => a.order - b.order);
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      doFetchProject: 'portfolio/doFetchProject',
    }),
    async doFetchNextProject() {
      console.log(this.$route.params);
      this.$router.push(
        `/portfolio/${this.$route.params.category}/${this.nextProject[0].id}`,
      );
      await this.doFetchProject(this.$route.params.id);
      // document.getElementById('background-triangle').style.background="linear-gradient(to bottom right, transparent 0%, transparent 50%," + this.project.hexa + " 50%",+ this.project.hexa + " 100% )"
    },
    async doFetchPreviousProject() {
      console.log(this.$route.params);
      this.$router.push(
        `/portfolio/${this.$route.params.category}/${this.previousProject[0].id}`,
      );
      await this.doFetchProject(this.$route.params.id);
      // document.getElementById('background-triangle').style.background="linear-gradient(to bottom right, transparent 0%, transparent 50%," + this.project.hexa + " 50%",+ this.project.hexa + " 100% )"
    },
  },
};
